import React, { useState, useRef, useEffect } from "react";

import Slider from "react-slick";

import Arrow from "../Arrow";
import FullscreenGallery from "../FullscreenGallery";

import LazyImageBackground from "../../../Images/LazyImageBackground";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.css";
import { Tooltip } from "react-leaflet";

const Gallery = ({ images }) => {

    // Fullscreen gallery
    const [fullscreen, setFullscreen] = useState(false);

    // Gallery
    const slider = useRef();

    const [index, setIndex] = useState(0);

    const settings = {
        className: "normalGallery center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        nextArrow: <Arrow side="right" />,
        prevArrow: <Arrow side="left" />,
        beforeChange: (current, next) => setIndex(next),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            },
        ]
    };

    useEffect(() => {
        slider.current.slickGoTo(index);
    }, [index]);

    return (
        <div
            className="normalGallery"
        >
            <FullscreenGallery
                open={fullscreen}
                setOpen={setFullscreen}
                images={images}
                setIndex={setIndex}
                index={index}
            />
            <div>
                <Slider
                    {...settings}
                    ref={slider}
                >
                    {
                        images.sort((a, b) => a.order - b.order).map(
                            image =>
                                <div
                                >
                                    <LazyImageBackground
                                        src = {
                                            process.env.REACT_APP_STATIC + image.image
                                        }
                                        style={{
                                            height: "40vh",
                                            marginBottom: "-50px",
                                        }}
                                        onClick={() => setFullscreen(true)}
                                    >
                                    </LazyImageBackground>
                                </div>
                        )
                    }
                </Slider>
            </div>
        </div>

    );
}

export default Gallery;