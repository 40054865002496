import React from "react";

const NavItem = ({index, m}) => {

    let liClass = m.children.length>0 ? " dropdown " : "";
    let aClass = m.children.length>0 ? " dropdown-toggle " : "";
    aClass += index===0 ? " ms-auto " : " ms-lg-3 ";

    return (
        <li 
            className={"nav-item " + liClass}
            id={"menuItem"+index}
        >
            <a
                className={"nav-link p-0 " + aClass}
                href={m.link}
                data-bs-toggle={m.children.length>0 && "dropdown"}
                aria-expanded={m.children.length>0 && "false"} 
                id={m.children.length>0 && "dropDown" + index}
                target={m.blank && "_blank"}
            >
                {m.icon && <i className={"me-2 " + m.icon}></i>}
                {m.label}
            </a>
            {m.children.length> 0 &&
                <div className="dropdown-menu p-0" aria-labelledby={"dropDown" + index}>
                    {
                        m.children.map(child => 
                            <a 
                                className="dropdown-item" 
                                href={child.link} 
                                target={child.blank && "_blank"}>
                                {child.label}
                            </a>
                        )
                    }
                </div>
            }
        </li>
    );
}

export default NavItem;