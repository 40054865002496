import React, {useState, useEffect} from "react";

import {
    Grow
} from '@material-ui/core';
import Loading from "../../Layouts/Loading";


// Get url parameters
import {
    useLocation
} from 'react-router-dom';

const secondsTimeSpanToHMS = (s) => {
    var m = Math.floor(s / 60); //Get remaining minutes
    s -= m * 60;
    return (m>0 ? (m < 10 ? '0' + m : m) + " minutos e " : "") + (s < 10 ? '0' + s : s) + " segundos"; //zero padding on minutes and seconds
}

const Maintenance = () => {

    // Countdown
    const [time, setTime] = useState(60);
    const [timeStr, setTimeStr] = useState('');

    const countdown = () => {
        if (time>0) {
            setTime(old => old-1);
            setTimeout(countdown, 1000);
        }
    }

    // Redirect when back up
    const [loading, setLoading] = useState(true);
    const search = useLocation().search;
    useEffect(() => {
        fetch(process.env.REACT_APP_API + "/pages/menus/")
            .then(res => {
                if (!res.ok) { throw res }
                const goBackTo = new URLSearchParams(search).get("goBackTo");
                window.location.href = (!goBackTo || goBackTo.indexOf('404')>=0 ? '/' : goBackTo);
            })
            .catch(err => {
                setLoading(false);
                setTimeout(countdown, 1000);
            })
    }, []);

    useEffect(() => {
        setTimeStr(secondsTimeSpanToHMS(time));
        time===0 && window.location.reload();
    }, [time]);

    return (
        <div
            style={{
                backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + process.env.PUBLIC_URL + "/static/react/home/background.jpg)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                minHeight: "100vh"
            }}
            className="d-flex flex-column"
        >
            {

                loading
                ?
                <Loading />
                :
                <Grow in={!loading}>
                    <div
                        className="mx-auto col-12 col-lg-9 px-3 px-lg-0 z-100 d-flex flex-column flex-grow-1"
                    >
                        <img 
                            src={process.env.PUBLIC_URL + "/static/react/logo.png"} alt="Logótipo Chave Lusa"
                            className="mx-auto col-6 col-md-2 mt-5"
                        />
                        <h1
                            className="text-center font-weight-bold text-white display-4 mt-4 mb-5"
                        >
                            EM MANUTENÇÃO
                        </h1>
                        <h3 className="text-center text-white display-8 mb-4">
                            Por motivos de manutenção o nosso portal encontra-se indisponível. Prometemos ser breves!
                        </h3>
                        <p className="text-center text-white">
                            Para sua comodidade, pode deixar a página aberta e iremos recarregá-la a cada minuto (faltam {timeStr}) até que a página volte a estar disponível. 
                        </p>
                    </div>
                </Grow>
            }
        </div>
    );
}

export default Maintenance;