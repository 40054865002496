import React, { useEffect, useState } from "react";

import "./index.css";

// flex is used for views.Pages image to work
// however, in views.Property gallery, if flex was activated it was visible a white bar

const LazyImageBackground = ({className, src, style, onClick, title, children, flex}) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const img = new Image();
        img.src = src;
        img.onload = () => setLoading(false);
    }, [src]);

    let className2 = "";
    if (flex) {
        className += " d-flex";
    } else {
        className2 += "h-100";
    }

    return (
        <div
            className={className + " lazyimagebk"}
            style={style}
            onClick={onClick}
            title={title}
        >
            <div
                className={className2 + " col-12 lazyimagebkimg"}
                style={{
                    opacity: loading ? 0 : 1,
                    backgroundImage: "url(\"" + src + "\")",
                }}
            >
                {children}
            </div>
        </div>
    );
}

export default LazyImageBackground;