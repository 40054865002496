import { MapContainer, TileLayer, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './index.css';

const ChaveLusaMap = ({ coordinates }) => {

    return (
        <MapContainer center={coordinates} zoom={13} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Circle
                center={coordinates}
                radius={1500}
                fillOpacity={0.2}
                fillColor="#00748D"
                color="#00748D"
            >
            </Circle>
        </MapContainer>
    );
}

export default ChaveLusaMap;