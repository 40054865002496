import React, { useState } from "react";

import {
    Dialog,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ReCAPTCHA from "react-google-recaptcha";

import validateForm from "../../Forms/functions";
import Alert from "../../Forms/Alert";
import Loading from "../../../Layouts/Loading";

const useStyles = makeStyles({
    dialog: {
        padding: '1.5rem',
        borderRadius: 0,
    },
});


const EmailShareDialog = ({ open, setOpen, property }) => {
    // Styling
    const classes = useStyles();

    // Form
    const [form, setForm] = useState({
        'name': null,
        'email': null,
        'policy': false,
        'recaptcha': null,
    });
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);

    // Form change
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        setForm(f => ({
            ...f,
            [name]: type === "checkbox" ? checked : value,
        }));
    }

    const recaptcha = (value) => {
        setForm(f => ({
            ...f,
            'recaptcha': value
        }));
    }

    // Form submit and validation
    const [alert, setAlert] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    const attrsClass = {
        'name': 'str',
        'email': 'email',
        'policy': 'bool',
        'recaptcha': 'str',
    }

    const attrsName = {
        'name': 'Nome',
        'email': 'Email',
        'policy': 'Política de privacidade',
        'recaptcha': 'ReCaptcha',
    }

    const submit = () => {
        // Validate form and show alert if error
        let errors = validateForm(form, attrsClass, attrsName);
        setAlert(errors);
        console.log(errors);
        if (errors.length > 0) { setShowAlert(true); return; }

        setSending(true);

        // Submit form to API
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                emailShare: "",
                emailShareName: form.name,
                emailShareEmail: form.email,
                emailShareRGPD: form.policy
            })
        };

        fetch(process.env.REACT_APP_API + '/realstate/properties/' + property.id + '/share', requestOptions)
            .then(response => response.json())
            .then(data => {
                setSending(false);
                // Log feedback
                if ('success' in data) {
                    // Confirm user has sent the form!
                    // Show user feedback
                    setSent(true);
                } else {
                    setAlert('Ocoreu um erro, por favor tente novamente');
                    setShowAlert(true);
                    console.error("Error registering email share!");
                }
            })
            .catch((error) => {
                setSending(false);
                setAlert('Ocoreu um erro, por favor tente novamente');
                setShowAlert(true);
                console.error("Error registering email share!", error);
            });
    }

    const close = () => {
        setOpen(false);
        // setSent(false);
    }


    return (
        <Dialog
            onClose={close}
            aria-labelledby="sortingDialog"
            open={open}
            classes={{ paper: classes.dialog }}
            style={{
                display: showAlert ? "none" : "block"
            }}
        >

            {
                sent
                    ?
                    <>
                        <h6 class="font-weight-bold text-primary">Partilha bem sucedida!</h6>
                        <p
                            className="m-0"
                        >
                            A sua mensagem foi enviada com sucesso.
                        </p>
                        <button
                            class="btn btn-outline-primary mt-3 col-12"
                            onClick={close}
                        >
                            Fechar
                        </button>
                    </>
                    :
                    sending
                        ?
                        <Loading />
                        :
                        <>
                            <h6 class="font-weight-bold text-primary">Partilhe esta propriedade por email!</h6>
                            <p
                                className="m-0"
                            >
                                Só tem de preencher o seu nome e o email do destinatário e nós tratamos do resto.
                            </p>

                            <TextField
                                id="name"
                                label="O seu nome"
                                type="text"
                                className="my-2"
                                name="name"
                                onChange={handleChange}
                                value={form.name}
                            />

                            <TextField
                                id="email"
                                label="O email do destinatário"
                                type="email"
                                className="my-2"
                                name="email"
                                onChange={handleChange}
                                value={form.email}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={form.policy}
                                        onChange={handleChange}
                                        name="policy"
                                        color="primary"
                                    />
                                }
                                label="Aceito a política de privacidade."
                                className="my-2"
                            />

                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA}
                                onChange={recaptcha}
                            />

                            <div className="d-flex flex-row">
                                <button
                                    class="btn btn-outline-primary mt-3 col me-auto"
                                    onClick={close}
                                >
                                    Fechar
                                </button>

                                <button
                                    class="btn btn-primary mt-3 ms-3 col-8"
                                    onClick={submit}
                                >
                                    Enviar
                                </button>
                            </div>

                        </>
            }

            {
                // Feedback
            }

            <Alert
                alert={alert}
                show={showAlert}
                setShow={setShowAlert}
            />

        </Dialog>
    );
}

export default EmailShareDialog;