import React from "react";

const Features = ({ features }) => {
    return (
        <div>
            <h5 className="mt-5 font-weight-bold">Características</h5>
            <div className="d-flex flex-row flex-wrap mx-0">
                {
                    features.map(
                        feature =>
                            <p className="p-2 bg-primary text-white me-2 mb-1 tag">
                                <i
                                    className={"me-2 " + feature.icon}></i>
                                {feature.name}
                            </p>
                    )
                }

            </div>
        </div>
    );
}

export default Features;