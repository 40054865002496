import React from "react";

const Body = ({children}) => {
    return (
        <div className="bg-secondary main d-flex">
            <div class="col-12 col-lg-9 px-3 px-lg-0 mx-auto px-0 pt-5 d-flex">
                {children}
            </div>
        </div>
    );
}

export default Body;