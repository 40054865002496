import React from "react";

import YoutubeEmbed from "../../Components/YoutubeEmbed";

const Description = ({ property }) => {

    // If youtube url, add embed video
    let videoId = null;
    if (property.description.indexOf("youtube.com/watch?v=") >= 0) {
        videoId = property.description.split("youtube.com/watch?v=")[1].split('"')[0];
    }

    // When user clicks on video, do not open youtube, instead, scroll to element
    const handleClick = (event) => {
        if (videoId && event.target.hasAttribute('href') && event.target.href.indexOf(videoId) && document.getElementById('youtube')) {
            event.preventDefault();
            document.getElementById('youtube').scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <div>
            <h5 className="mt-5 font-weight-bold mb-0">Descrição</h5>
            <small className="text-secondary mb-2">Referência <span className="font-weight-bold">{property.id}</span></small>
            <div
                className="text-justify"
                dangerouslySetInnerHTML={{ __html: property.description }}
                onClick={handleClick}
            ></div>
            {
                videoId &&
                <div id="youtube">
                    <YoutubeEmbed
                        embedId={videoId}
                    />
                </div>
            }
        </div>
    );
}

export default Description;