import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';

import {
    Dialog,
    Grow,
    Tooltip
} from '@material-ui/core';

import "./index.css";


const useStyles = makeStyles({
    dialog: {
        padding: '1.5rem',
        borderRadius: 0,
    },
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const Video = ({ video }) => {

    // Dialog
    const [open, setOpen] = useState(false);
    // > Styling
    const classes = useStyles();

    // Player reference
    const [videoPlayer, setVideoPlayer] = useState(null);

    return (
        video &&
        <div
            className="mb-5 video bg-secondary"
        >
            <ReactPlayer
                ref={(ref) => setVideoPlayer(ref)}
                url={video}
                width='100%'
                light={true}
                onClickPreview={() => {
                    // When user clicks on preview, instead of loading player, open dialog with "real" player
                    videoPlayer.showPreview();
                    setOpen(1);
                }}
            />
            <Dialog
                fullScreen
                open={open}
                onClose={() => setOpen(false)}
                TransitionComponent={Transition}
                className="fullscreen"
            >
                <div className="col-10 mx-auto my-auto pt-5">
                    <ReactPlayer
                        url={video}
                        width='100%'
                        height='80vh'
                        controls={true}
                    />
                </div>
                <Tooltip title="Fechar vídeo">
                    <img
                        className="link position-absolute t-4 r-4 p-3"
                        src={process.env.PUBLIC_URL + "/static/react/gallery/close.png"}
                        onClick={() => setOpen(false)}
                        alt="Fechar"
                    />
                </Tooltip>
            </Dialog>
        </div>
    );
}

export default Video;