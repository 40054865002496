import React from "react";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { useRoutes } from 'react-router-dom';
import routes from './routes';

import Cookies from "./Global/Cookies";

// Theme customization
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00748D'
        }
    }
});

const App = () => {
    const routing = useRoutes(routes);

    return (
        <MuiThemeProvider theme={theme}>
            <Cookies />
            {routing}
        </MuiThemeProvider>
    );
}

export default App;