import React, { useState } from "react";

import {
    TextField,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';

import ReCAPTCHA from "react-google-recaptcha";

import validateForm from "../../Forms/functions";
import Alert from "../../Forms/Alert";

import Loading from "../../../Layouts/Loading";

const ContactForm = ({propertyID}) => {

    // Loading (when submitting form)
    const [loading, setLoading] = useState(false);

    // Form
    const [form, setForm] = useState({
        'name': null,
        'email': null,
        'contact': null,
        'message': null,
        'policy': false,
        'recaptcha': null,
    });

    // Form change
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        setForm(f => ({
            ...f,
            [name]: type === "checkbox" ? checked : value,
        }));
    }

    const recaptcha = (value) => {
        setForm(f => ({
            ...f,
            'recaptcha': value
        }));
    }

    // Form submit and validation
    const [alert, setAlert] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    const attrsClass = {
        'name': 'str',
        'email': 'email',
        'contact': 'phone',
        'policy': 'bool',
        'recaptcha': 'str',
        'message': 'str',
    }

    const attrsName = {
        'name': 'Nome',
        'email': 'Email',
        'contact': 'Contacto',
        'policy': 'Política de privacidade',
        'recaptcha': 'ReCaptcha',
        'message': 'Mensagem'
    }

    const submit = () => {
        // Validate form and show alert if error
        let errors = validateForm(form, attrsClass, attrsName);
        setAlert(errors);
        if (errors.length > 0) { setShowAlert(true); return; }

        // Enable loading
        setLoading(true);

        // Submit form to API
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...form,
                property: propertyID
            })
        };
        
        fetch(process.env.REACT_APP_API + '/crm/contacts/', requestOptions)
            .then(response => response.json())
            .then(data => {
                // Show feedback
                if('error' in data) {
                    setAlert(data['error']);
                } else if ('client' in data) {
                    setAlert([]);
                    // Reset form
                    setForm({
                        'name': "",
                        'email': "",
                        'contact': "",
                        'message': "",
                        'policy': false,
                        'recaptcha': null,
                    });
                } else {
                    setAlert('Ocorreu um erro. Tente novamente.');
                }
                // Show alert (for success or not)
                setShowAlert(true);
                // Disable loading 
                setLoading(false);
            })
            .catch((error) => {
                // Give user feedback
                setAlert('Ocorreu um erro na submissão do formulário. Tente novamente.');
                // Show alert (for success or not)
                setShowAlert(true);
                // Disable loading 
                setLoading(false);
            });
    }


    return (
        <div className="bg-white p-4">
            <h5 class="font-weight-bold">Está interessado?</h5>
            <h6>Contacte-nos!</h6>

            {
                loading 
                ?
                <Loading />
                : 
                <>

                    <TextField
                        id="contact_name"
                        label="Nome"
                        type="text"
                        className="col-12 my-2"
                        name="name"
                        onChange={handleChange}
                        value={form.name}
                    />

                    <TextField
                        id="contact_email"
                        label="Email"
                        type="email"
                        className="my-2 col-12"
                        name="email"
                        onChange={handleChange}
                        value={form.email}
                    />

                    <TextField
                        id="contact_contact"
                        label="Contacto"
                        type="text"
                        className="my-2 col-12"
                        name="contact"
                        onChange={handleChange}
                        value={form.contact}
                    />

                    <TextField
                        id="contact_message"
                        label="Mensagem"
                        multiline
                        rows={4}
                        defaultValue=""
                        name="message"
                        className="my-2 col-12"
                        onChange={handleChange}
                        value={form.message}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={form.policy}
                                onChange={handleChange}
                                name="policy"
                                color="primary"
                            />
                        }
                        label="Aceito a política de privacidade."
                        className="my-2"
                    />

                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={recaptcha}
                    />

                    <button
                        class="btn btn-primary mt-3 col-12"
                        onClick={() => submit()}
                    >
                        Submeter
                    </button>
                </>
            }


            {
                // Feedback
            }

            <Alert
                alert={alert}
                show={showAlert}
                setShow={setShowAlert}
            />

        </div>
    );
}

export default ContactForm;