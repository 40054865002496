import React, { useState, useEffect } from 'react';

import TopBar from "../../Layouts/Topbar";
import Body from "../../Layouts/Body";

import PropertyService from '../../Services/Property';

import Loading from "../../Layouts/Loading";
import PropertySquare from "../../Components/Property/PropertySquare";
import Pagination from "../../Components/Pagination";
import Filter from "../../Components/Property/Filter";
import Sort from "../../Components/Property/Sort";

// Get url parameters
import {
    useLocation
} from 'react-router-dom';

let aborter = null;

const PropertyFavorites = () => {

    // Pagination
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [items, setItems] = useState(0);

    // Loading
    const [loading, setLoading] = useState(true);

    // Sorting
    const [sortAttr, setSortAttr] = useState("price");
    const [sortInverse, setSortInverse] = useState("-");

    // Data from API
    const [properties, setProperties] = useState([]);
    useEffect(() => {
        if (PropertyService.getFavorites().length === 0) {
            setLoading(false);
            return;
        }
        let url = process.env.REACT_APP_API + "/realstate/properties/";
        // Pagination
        url += "?page=" + page;
        // Sort
        url += "&ordering=" + sortInverse + sortAttr;
        // Favorites
        url += '&ids=' + PropertyService.getFavorites().join('&ids=');
        fetch(url)
            .then(res => res.json())
            .then(json => {
                setProperties(json['results']);
                setPage(json['currentPage']);
                setItemsPerPage(json['itemsPerPage']);
                setItems(json['count']);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [page, sortAttr, sortInverse]);

    // Scroll top on page change
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [page]);

    return (
        <div>
            <TopBar>
                <h4 class="font-weight-bold text-light mb-0">
                    Favoritos
                </h4>
            </TopBar>
            <Body>
                <div className="col-12 d-flex flex-row flex-wrap">
                    <div className="col-12">
                        {
                            loading
                                ?
                                <Loading />
                                :
                                properties.length > 0
                                    // If there are properties to show, show them
                                    ?
                                    <div className="col-12 d-flex flex-row flex-wrap">
                                        {
                                            properties.map(
                                                property =>
                                                    <PropertySquare
                                                        property={property}
                                                        colsNumber={3}
                                                        showAgent={true}
                                                        removeFavorite={true}
                                                    />
                                            )
                                        }
                                        <Pagination
                                            current={page}
                                            itemsPerPage={itemsPerPage}
                                            items={items}
                                            handleChange={setPage}
                                            className="mb-5"
                                        />
                                    </div>
                                    :
                                    // If not, show erros message 
                                    <div>
                                        <h5 className="font-weight-bold">Sem imóveis favoritos!</h5>
                                        <p>
                                            Para adicionar propriedades aos seus favoritos, clique no botão "Adicionar aos favoritos" na página da propriedade.
                                        </p>
                                        <a href='/propriedades'>
                                            <button className="btn btn-primary">Propriedades</button>
                                        </a>
                                    </div>
                        }
                    </div>
                </div>
            </Body >
        </div >
    );
}

export default PropertyFavorites;