import React from "react";

import ShareThis from "../../Components/Social/ShareThis";
import EmailShare from "../../Components/Property/EmailShare";

const Share = ({className, property}) => {

    const sendStats = (network) => {        
        // Submit form to API
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                socialShare: "",
                socialnetwork: network
            })
        };
        
        fetch(process.env.REACT_APP_API + '/realstate/properties/' + property.id + '/share', requestOptions)
            .then(response => response.json())
            .then(data => {
                // Log feedback
                if('error' in data) {
                    console.error("Error registering share for network", network);
                } 
            })
            .catch((error) => {
                console.error("Error registering share for network", network, error);
            });
    }

    return (
        <div
            className={className}
            id="propertyShare"
        >
            <h5 class="mt-5 font-weight-bold">Partilhe esta propriedade!</h5>
            <div
                className="d-flex flex-row"
            >
                <div
                    className="me-0"
                >
                    <ShareThis
                        click={sendStats}
                    />
                </div>
                <div className="flex-grow">
                    <EmailShare 
                        className="h-100"
                        property={property}
                    />
                </div>
            </div>
        </div>
    );
}

export default Share;