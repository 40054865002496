import React, { useRef } from "react";

import {
    Dialog,
    Grow,
    Tooltip,
} from '@material-ui/core';

import Slider from "react-slick";

import Arrow from "../Arrow";

import LazyImage from "../../../Images/LazyImage";

import "./index.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const Fullscreen = ({ open, setOpen, images, index, setIndex }) => {

    // Dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Gallery
    const slider = useRef();

    const settings = {
        className: "mx-auto my-auto",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <Arrow side="right" />,
        prevArrow: <Arrow side="left" />,
        initialSlide: index,
        beforeChange: (current, next) => setIndex(next),
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={(handleClose)}
            TransitionComponent={Transition}
            className="fullscreen"
        >
            {
                // Slider
            }
            <Slider
                {...settings}
                ref={slider}
            >
                {
                    images.sort((a, b) => a.order - b.order).map(
                        image =>
                            <LazyImage 
                                src={process.env.REACT_APP_STATIC + image.image}
                            />
                    )
                }
            </Slider>
            {
                // Tips 
            }
            <p className="text-white text-center small d-lg-none">
                <i className="fas fa-sync-alt me-2"></i>Em disposivos móveis aconselha-se a visualização horizontal.
            </p>
            <p className="text-white text-center small d-none d-lg-block">
                <i className="fas fa-info-circle me-2"></i>Utilize a tecla esc para sair.
            </p>

            <Tooltip title="Fechar galeria">
                <img
                    className="link position-absolute t-4 r-4 p-3"
                    src={process.env.PUBLIC_URL + "/static/react/gallery/close.png"}
                    onClick={handleClose}
                    alt="Fechar"
                />
            </Tooltip>
        </Dialog>
    );
}

export default Fullscreen;