import React from "react";
import { Outlet } from 'react-router-dom';

import Footer from "./Footer";

const CleanLayout = () => {
    return (
        <div className="col-12 d-flex flex-column">
            <Outlet />
            {
                window.location.href.indexOf('manutencao')<0 &&
                <Footer />
            }
        </div>
    );
}

export default CleanLayout;