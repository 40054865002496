import React, { useState, useEffect } from 'react';

import {
    useParams
} from "react-router-dom";

import { Grow } from '@material-ui/core';

import Loading from "../../Layouts/Loading";

import NormalGallery from "../../Components/Property/Gallery/NormalGallery";
import Header from "./Header";
import Numbers from "./Numbers";
import Description from "./Description";
import Features from "./Features";
import Attributes from "./Attributes";
import Share from "./Share";
import ChaveLusaMap from '../../Components/Map';

import Video from "./Video";
import AgentCard from "../../Components/Agents/AgentCard";
import ContactForm from "../../Components/Property/ContactForm";

import PropertyService from '../../Services/Property';

const Property = () => {

    // URL Data
    let { id } = useParams();

    // Loading
    const [loading, setLoading] = useState(true);

    // Favorite
    const [favorite, setFavorite] = useState(false);
    const updateFavorite = (pid) => {
        setFavorite(PropertyService.getFavorites().indexOf(pid) >= 0);
    }

    // API data
    const [property, setProperty] = useState(null);
    useEffect(() => {
        fetch(process.env.REACT_APP_API + "/realstate/properties/" + id)
            .then(res => res.json())
            .then(json => {
                // 404 Error
                if ('detail' in json && json['detail'] === 'Not found.') {
                    window.location.href = "/404";
                }
                setProperty(json);
                updateFavorite(json.id);
                setLoading(false);
            })
            .catch((error) => {
                window.location.href = "/404";
            });
    }, [id]);

    return (
        <div
            className="bg-secondary d-flex flex-column"
        >
            {
                property &&
                <>
                    <Grow
                        in={!loading}
                    >
                        <NormalGallery
                            images={property.images}
                        />
                    </Grow>
                </>
            }

            <Header
                property={property}
            />

            {
                loading &&
                <div className="main d-flex">
                    <Loading />
                </div>
            }

            {
                property &&
                <div class="col-12 col-lg-9 px-3 px-lg-0 row mx-auto py-5">

                    <Grow
                        in={!loading}
                    >
                        <div class="col-12 col-xl-7 p-0">
                            <Numbers
                                property={property}
                            />
                            <Description
                                property={property}
                            />
                            {
                                property.feature.length > 0 &&
                                <Features
                                    features={property.feature}
                                />
                            }
                            {
                                property.coordinates && property.coordinates.split(',').length===2 && property.coordinates.split(',').every(n => !isNaN(n)) &&
                                <div>
                                    <h5 className="mt-5 font-weight-bold">Localização</h5>
                                    <div className="d-flex flex-row flex-wrap mx-0">
                                        <ChaveLusaMap coordinates={property.coordinates.split(',').map(n => parseFloat(n))} />
                                    </div>
                                </div>
                            }
                            <Attributes
                                property={property}
                            />
                            <Share
                                className="mb-5 mb-xxl-0"
                                property={property}
                            />
                        </div>
                    </Grow>
                    <Grow
                        in={!loading}
                    >
                        <div class="col-12 col-xl-4 px-0 ms-auto animated fadeInRight">
                            <div className='col-12 mb-5'>
                                <button
                                    className={"btn mb-3 col-12 " + (favorite ? 'btn-outline-primary' : 'btn-primary')}
                                    onClick={() => {
                                        if (!favorite)
                                            PropertyService.addToFavorites(property.id);
                                        else
                                            PropertyService.removeFromFavorites(property.id);
                                        updateFavorite(property.id);
                                    }}
                                >
                                    {
                                        favorite
                                            ? <><i className={"me-2 fas fa-heart"}></i>Remover dos favoritos</>
                                            : <><i className={"me-2 far fa-heart"}></i>Adicionar aos favoritos</>
                                    }
                                </button>
                                <button
                                    className="btn col-12 btn-outline-primary"
                                    onClick={() => {
                                        document.getElementById('propertyShare').scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    <i className={"me-2 fas fa-share"}></i>Partilhar
                                </button>
                            </div>
                            <Video
                                video={property.video ? property.video.url : null}
                            />
                            <AgentCard
                                agent={property.agent}
                                agents={0}
                            />
                            <ContactForm
                                propertyID={property.id}
                            />
                        </div>
                    </Grow>
                </div>
            }

        </div>
    );
}

export default Property;