import React from "react";

import { InlineShareButtons } from 'sharethis-reactjs';

import "./index.css";

const ShareThis = ({click}) => {

    // Process user clicks (identify network)
    const userClick = (event) => {
        let network = "";
        if(event.target.hasAttribute('alt') && event.target.alt !== null) {
            network = event.target.alt.split(" ")[0];
        } else if (event.target.hasAttribute('data-network') && event.target.getAttribute('data-network') !== null) {
            network = event.target.getAttribute('data-network');
        }
        if (click !== undefined) {
            click(network);
        }
    }

    return (
        <div
            onClick={userClick}
        >
            <InlineShareButtons
                config={{
                    alignment: 'left',    // alignment of buttons (left, right)
                    color: 'social',      // set the color of buttons (social, white)
                    enabled: true,        // show/hide buttons (true, false)
                    font_size: 16,        // font size for the buttons
                    hide_desktop: false,  // hide buttons on desktop (true, false)
                    labels: 'null',     // button labels (cta, counts, null)
                    language: 'pt',       // which language to use (see LANGUAGES)
                    networks: [           // which networks to include (see SHARING NETWORKS)
                        'facebook',
                        'twitter',
                        'messenger',
                        'whatsapp',
                        'telegram',
                        'linkedin',
                        'pinterest',
                    ],
                    padding: 12,          // padding within buttons (INTEGER)
                    radius: 4,            // the corner radius on each button (INTEGER)
                    show_total: false,     // show/hide the total share count (true, false)
                    size: 50,             // the size of each button (INTEGER)
    
                    // OPTIONAL PARAMETERS
                    message: 'custom email text',     // (only for email sharing)
                    subject: 'custom email subject',  // (only for email sharing)
                }}
            />
        </div>
    );
}

export default ShareThis;