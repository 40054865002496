import React from "react";

import LazyImageBackground from "../../Components/Images/LazyImageBackground";

const PageImage = ({image}) => {
    return (
        <LazyImageBackground 
            className="jumbotron-fluid bkimage" 
            src={
                process.env.REACT_APP_STATIC + image
            }
            flex={true}
        >
        </LazyImageBackground>
    );
}

export default PageImage;