import React, { useState, useEffect } from "react";

import {
    Dialog,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Loading from "../../../Layouts/Loading";

const useStyles = makeStyles({
    dialog: {
        padding: '1.5rem',
        borderRadius: 0,
    },
});

const Sort = ({ open, attr, inverse, onClose }) => {
    // Styling
    const classes = useStyles();

    // Loading
    const [loading, setLoading] = useState(true);

    // Data from API
    const [sorts, setSorts] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_API + "/realstate/sorts/")
            .then(res => res.json())
            .then(json => {
                setSorts(json);
                setLoading(false);
            })
    }, []);

    // Handle value change
    const handleClose = () => {
        onClose(null, null);
    };
    const handleListItemClick = (event, value) => {
        const v = value.split("/");
        onClose(v[0], v[1] === "desc" ? "-" : "");
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="sortingDialog"
            open={open}
            classes={{ paper: classes.dialog }}
        >
            <h6 class="font-weight-bold">Ordenação</h6>
            {
                loading
                ?
                <Loading />
                :
                <RadioGroup aria-label="sort" name="sort" value={inverse ? attr + "/desc" : attr + "/asc"} onChange={handleListItemClick}>
                    {
                        sorts.map(
                            s =>
                            <div className="d-flex flex-column">
                                <FormControlLabel value={s.value + "/asc"} control={<Radio color="primary" />} label={s.label + " (Ascendente)"} />
                                <FormControlLabel value={s.value + "/desc"} control={<Radio color="primary" />} label={s.label + " (Descendente)"} />
                            </div>
                        )
                    }
                </RadioGroup>
            }
        </Dialog>
    );
}

export default Sort;