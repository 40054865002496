import React, { useState, useEffect } from "react";

/**
 * This component provides pagination
 * 
 * current          Current page number
 * (The first page is 1)
 * items            Number of objects (total)
 * itemsPerPage     Number of objects per page
 * handleChange     Function used to change current page
 * className        Extra classes
 */

const Pagination = ({ current, items, itemsPerPage, handleChange, className }) => {

    const [pagesNumber, setPagesNumber] = useState(0);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        setPagesNumber(items && itemsPerPage ? Math.ceil(items / itemsPerPage) : 0);
    }, [items, itemsPerPage]);


    useEffect(() => {
        if (pagesNumber === 0) {
            return false;
        }

        const pagesArray = [];
        if (pagesNumber < 5) {
            for (let i = 1; i <= pagesNumber; i++) {
                pagesArray.push(i);
            }
        } else {
            // 1 2 3 ...  X
            if (current <= 2) {
                for (let i = 1; i <= 3; i++) {
                    pagesArray.push(i);
                }
                pagesArray.push(-1);
                pagesArray.push(pagesNumber);
                // 1 ...  X-2 X-1 X
            } else if (current >= pagesNumber - 1) {
                pagesArray.push(1);
                pagesArray.push(-1);
                for (let i = pagesNumber - 2; i <= pagesNumber; i++) {
                    pagesArray.push(i);
                }
                // ... X-1 X X+1 ...
            } else {
                let currentpage = parseInt(current);
                pagesArray.push(-1);
                pagesArray.push(currentpage - 1);
                pagesArray.push(currentpage);
                pagesArray.push(currentpage + 1);
                pagesArray.push(-1);
            }
        }
        setPages(pagesArray);

        console.log("PAGES", pagesArray);
    }, [pagesNumber, current, items, itemsPerPage]);

    return (
        <div className={className ? "col-12 d-flex " + className : "col-12 d-flex"}>
            <div className="mx-auto">
                <button
                    className={
                        current===1 
                            ? "py-2 px-2 btn text-white me-2 mb-0 btn-outline-primary disabled"
                            : "py-2 px-2 btn text-white me-2 mb-0 btn-outline-primary"

                    }
                    onClick={() => handleChange(1)}
                >
                    <i class="fas fa-angle-double-left"></i>
                </button>
                {
                    pages.map(p => {
                        let colorClass = (p === current) ? "btn-primary" : "btn-outline-primary";
                        if (p < 0)
                            colorClass += ' disabled';
                        return (
                            <button
                                className={
                                    p>0
                                        ? "py-2 px-3 btn text-white me-2 mb-0 " + colorClass
                                        : "py-2 px-2 btn text-white me-2 mb-0 " + colorClass
                                }
                                onClick={() => handleChange(p)}
                            >
                                {p > 0 ? p : '...'}
                            </button>
                        )
                    })
                }
                <button
                    className={
                        current===pagesNumber
                            ? "py-2 px-2 btn text-white me-2 mb-0 btn-outline-primary disabled"
                            : "py-2 px-2 btn text-white me-2 mb-0 btn-outline-primary"

                    }
                    onClick={() => handleChange(pagesNumber)}
                >
                    <i class="fas fa-angle-double-right"></i>
                </button>
            </div>
        </div>
    );
}

export default Pagination;

/* Usage

// Pagination
const [page, setPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(0);
const [items, setItems] = useState(0);

// useEffect
setPage(json['currentPage']);
setItemsPerPage(json['itemsPerPage']);
setItems(json['count']);

// JSX
<Pagination
    current={page}
    itemsPerPage={itemsPerPage}
    items={items}
    handleChange={setPage}
/>

*/