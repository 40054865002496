import React, { useState, useEffect } from "react";

import { Grow } from '@material-ui/core';

import TopBar from "../../Layouts/Topbar";
import Body from "../../Layouts/Body";
import Loading from "../../Layouts/Loading";

import PageImage from "./PageImage";

import {
    useParams
} from "react-router-dom";

const Page = () => {
    let { id } = useParams();

    // Loading
    const [loading, setLoading] = useState(true);

    // Data from API
    const [page, setPage] = useState(null);
    useEffect(() => {
        fetch(process.env.REACT_APP_API + "/pages/statics/" + id)
            .then(res => res.json())
            .then(json => {
                // 404 Error
                if ('detail' in json && json['detail'] === 'Not found.') {
                    if (window.location.pathname !== "/404") {
                        window.location.href = "/404";
                    } else {
                        setLoading(false);
                    }
                } else {
                    setPage(json);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [id]);

    return (
        <div>
            {
                !loading && page && page.widgetImage && page.widgetImage.length > 0 &&
                <Grow
                    in={!loading}
                >
                    <PageImage
                        image={page.widgetImage[0].image}
                    />
                </Grow>
            }
            <TopBar>
                <h4 class="font-weight-bold text-light mb-0">
                    {
                        !loading &&
                        <>
                            {
                                page
                                    ?
                                    page.title
                                    :
                                    "Página não encontrada"
                            }
                        </>
                    }
                </h4>
            </TopBar>
            <Body>
                {
                    loading
                        // On loading show Loading gif
                        ?
                        <Loading />
                        // Otherwise, show page content
                        :
                        <Grow
                            in={!loading}
                        >
                            {
                                page
                                    ?
                                    <div
                                        className="col-12 bg-white p-3 p-md-5"
                                        dangerouslySetInnerHTML={{ __html: page.content }}
                                    >
                                    </div>
                                    :
                                    <div
                                        className="col-12 bg-white p-5"
                                    >
                                        <p>
                                            Lamentamos, mas a página a que está a tentar aceder não existe.
                                        </p>
                                        <p>
                                            Isto pode ter acontecido por a página estar temporariamente desativada, ter mudado de nome ou mesmo já não existir.
                                        </p>
                                        <a href="/">
                                            <button
                                                className="btn btn-primary"
                                            >
                                                Voltar à página inicial
                                            </button>
                                        </a>
                                    </div>
                            }

                        </Grow>
                }
            </Body>
        </div>
    );
}

export default Page;