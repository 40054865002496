import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from 'react-router-dom';

import App from "./App";

import "./Styles/bootstrap.min.css";
import "./Styles/chavelusa.css";

ReactDOM.render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
), document.getElementById('root'));