import React from "react";

const Attributes = ({ property }) => {
    return (
        <div>
            <h5 class="mt-5 font-weight-bold">Atributos</h5>
            <table class="table">
                <tbody>
                    {
                        property.house &&
                        <>
                            {
                                property.house.studentAccommodation &&
                                <tr>
                                    <td>Aceita estudantes</td>
                                    <th>Sim</th>
                                </tr>
                            }
                            {
                                property.house.affordableRents &&
                                <tr>
                                    <td>Programa rendas acessíveis</td>
                                    <th>Sim</th>
                                </tr>
                            }
                        </>
                    }
                    {
                        property.lot &&
                        <>
                            <tr>
                                <td>Propósito</td>
                                <th>{property.lot.purpose}</th>
                            </tr>
                            <tr>
                                <td>Tipo de terreno</td>
                                <th>{property.lot.landType}</th>
                            </tr>
                            <tr>
                                <td>Licença</td>
                                <th>{property.lot.license}</th>
                            </tr>
                            <tr>
                                <td>Inclinação</td>
                                <th>{property.lot.slope}</th>
                            </tr>
                        </>
                    }
                    {
                        property.store &&
                        <>
                            <tr>
                                <td>Tipo</td>
                                <th>{property.store.type}</th>
                            </tr>
                            <tr>
                                <td>Número de divisões</td>
                                <th>{property.store.divisions}</th>
                            </tr>
                            <tr>
                                <td>Número de pisos</td>
                                <th>{property.store.floors !== 3 ? property.store.floors : "3 ou +"}</th>

                            </tr>
                        </>
                    }
                    {
                        property.constructionYear &&
                        <tr>
                            <td>Ano de construção</td>
                            <th>{property.constructionYear}</th>
                        </tr>
                    }
                    {
                        property.conservation &&
                        <tr>
                            <td>Estado de conservação</td>
                            <th>{property.conservation}</th>
                        </tr>
                    }
                    {
                        property.details.map(
                            d =>
                                <tr>
                                    <td>{d.key}</td>
                                    <th>{d.value}</th>
                                </tr>
                        )
                    }
                </tbody>
            </table>

        </div>
    );
}

export default Attributes;