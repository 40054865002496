import React from 'react';
import {
    Dialog
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    dialog: {
        padding: '1.5rem',
        borderRadius: 0,
    },
});

/*
This is a component for a modal dialog for form state feedback
--- Parameters
    show    bool    True to show the dialog
    setShow func    Changes the display settings
    alert   list    List of invalid fields
            str     Customized error message
*/

const Alert = ({ show, setShow, alert }) => {

    // Styling
    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={show}
                onClose={() => setShow(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialog }}
            >
                <h6 class="font-weight-bold text-primary">
                    {
                        alert && alert.length === 0
                            ?
                            "Formulário enviado com sucesso."
                            :
                            "Ocorreu um erro na submissão"
                    }
                </h6>
                <p className="m-0">
                    {
                        alert && alert.length > 0 &&
                            Array.isArray(alert)
                            ?
                            <>
                                <p>
                                    Por favor verifique que preencheu corretamente os seguintes campos:
                                </p>
                                <ul>
                                    {
                                        alert.map(a => <li>{a}</li>)
                                    }
                                </ul>
                            </>
                            :
                            <p>
                                {alert}
                            </p>
                    }
                    {
                        alert && alert.length===0 &&
                        <p>
                            Iremos analisar a sua mensagem e entraremos em contacto tão breve quanto possível.
                        </p>
                    }
                </p>
                <button
                    class="btn btn-outline-primary mt-3 col-12"
                    onClick={() => setShow(false)}
                >
                    Fechar
                </button>
            </Dialog>
        </div>
    );

}

export default Alert;