import React from "react";
import MainLayout from "./Layouts/MainLayout";
import CleanLayout from "./Layouts/CleanLayout";

import PropertyList from "./Views/PropertyList";
import PropertyFavorites from "./Views/PropertyFavorites";
import Property from "./Views/Property";
import Homepage from "./Views/Homepage";
import Agent from "./Views/Agent";
import Page from "./Views/Page";
import Maintenance from "./Views/Maintenance";

const routes = [
	{
		path: "/",
		element: <CleanLayout />,
		children: [
			{ path: "/", element: <Homepage /> },
			{ path: "/manutencao", element: <Maintenance />}
		],
	},
	{
		path: "/",
		element: <MainLayout />,
		children: [
			{ path: "propriedades", element: <PropertyList /> },
			{ path: "favoritos", element: <PropertyFavorites /> },
			{ path: "propriedades/:id", element: <Property /> },
			{ path: "agente/:id", element: <Agent /> },
			{ path: ":id", element: <Page /> },
		],
	},
];

export default routes;
