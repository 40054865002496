import React, { useState, useEffect } from 'react';

import TopBar from "../../Layouts/Topbar";
import Body from "../../Layouts/Body";

import Loading from "../../Layouts/Loading";
import PropertySquare from "../../Components/Property/PropertySquare";
import Pagination from "../../Components/Pagination";
import Filter from "../../Components/Property/Filter";
import Sort from "../../Components/Property/Sort";

// Get url parameters
import {
    useLocation
} from 'react-router-dom';

let aborter = null;

const PropertyList = () => {

    // Pagination
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [items, setItems] = useState(0);

    // Loading
    const [loading, setLoading] = useState(true);
    const [reloading, setReloading] = useState(false);

    // filters
    const [filters, setFilters] = useState(null);

    // Sorting
    const [sortAttr, setSortAttr] = useState("price");
    const [sortInverse, setSortInverse] = useState("-");
    const [showSorting, setShowSorting] = useState(false);

    // Data from API
    const [properties, setProperties] = useState([]);
    useEffect(() => {
        // cancel pending request if any
        if(aborter) aborter.abort();
        // make our request cancellable
        aborter = new AbortController();
        const signal = aborter.signal;

        setReloading(true);
        let url = process.env.REACT_APP_API + "/realstate/properties/";
        // Pagination
        url += "?page=" + page;
        // Sort
        url += "&ordering=" + sortInverse + sortAttr;
        // Filtering
        if (filters != null) {
            Object.keys(filters).forEach((key, index) => {
                if (Array.isArray(filters[key])) {
                    filters[key].forEach(f => {
                        url += "&" + key + "=" + f;
                    });
                } else if (filters[key] != null) {
                    url += "&" + key + "=" + filters[key];
                }
            });
        }
        fetch(url, {signal: signal})
            .then(res => res.json())
            .then(json => {
                setProperties(json['results'].filter(p => p.images && p.images.length>0));
                setPage(json['currentPage']);
                setItemsPerPage(json['itemsPerPage']);
                setItems(json['count']);
                setLoading(false);
                setReloading(false);
            })
            .catch((error) => {
                setLoading(false);
                setReloading(false);
            });
    }, [page, sortAttr, sortInverse, filters]);

    // Get filtering parameters from url (only when rendered, not when state updated)
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    let query = useQuery();
    const [firstRender, setFirstRender] = useState(true);
    if (firstRender) {
        // Validate that not null and is number
        if (query.get("location") != null && !isNaN(query.get("location"))) {
            setFilters(prev => ({
                ...prev,
                'location': parseInt(query.get("location")),
            }));
        }
        // Validate that not null and is char
        if (query.get("state") != null && query.get("state").length === 1 && query.get("state").match(/[a-z]/i)) {
            setFilters(prev => ({
                ...prev,
                'state': query.get("state"),
            }));
        }
    
        // Remove data from URL
        var url = document.location.href;
        window.history.pushState({}, "", url.split("?")[0]);

        setFirstRender(false);
    }

    // Scroll top on page change
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [page, query]);

    return (
        <div>
            <TopBar>
                {
                    !loading &&
                    <>
                        <p
                            className="ms-auto mb-0 text-white link"
                            onClick={() => setShowSorting(true)}
                        >
                            <i className="fas fa-sort me-1"></i>
                            Ordenação
                        </p>
                        {
                            // filters
                            filters
                                ?
                                <p
                                    className="ms-3 mb-0 text-white link"
                                    onClick={() => setFilters(null)}
                                >
                                    <i className="fas fa-undo me-1"></i>
                                    Eliminar filtros
                                </p>
                                :
                                <p
                                    className="ms-3 mb-0 text-white link"
                                    onClick={() => setFilters({})}
                                >
                                    <i className="fas fa-sliders-h me-1"></i>
                                    Filtros
                                </p>
                        }
                    </>
                }
            </TopBar>
            <Body>
                <div className="col-12 d-flex flex-row flex-wrap">
                    <div className={filters ? "col-12 col-xl-8 col-xxl-9 order-2 order-xl-1" : "col-12"}>
                        {
                            loading 
                                ?
                                <Loading />
                                :
                                properties.length > 0
                                    // If there are properties to show, show them
                                    ?
                                    <div className="col-12 d-flex flex-row flex-wrap">
                                        {
                                            properties.map(
                                                property =>
                                                    <PropertySquare
                                                        property={property}
                                                        colsNumber={filters ? 2 : 3}
                                                        showAgent={true}
                                                    />
                                            )
                                        }
                                        <Pagination
                                            current={page}
                                            itemsPerPage={itemsPerPage}
                                            items={items}
                                            handleChange={setPage}
                                            className="mb-5"
                                        />
                                    </div>
                                    // If not, show erros message (if not reloading)
                                    :
                                    reloading
                                        ?
                                        <Loading />
                                        :
                                        <div className="col-12">
                                            {
                                                filters
                                                    // If filtering, sugest a less restrictive search
                                                    ?
                                                    <div>
                                                        <h5 className="font-weight-bold">Não encontrámos resultados para a sua pesquisa!</h5>
                                                        <p className="mb-0">Lamentamos, mas não dispomos de uma propriedade que corresponda a todos os critérios definidos. Tente fazer uma pesquisa menos restritiva ou elimine os filtros no botão abaixo.</p>
                                                        <button
                                                            className="btn btn-outline-primary my-3"
                                                            onClick={() => setFilters(null)}
                                                        >
                                                            <i className="fas fa-undo me-1"></i>
                                                            Eliminar filtros
                                                        </button>
                                                        <p className="mb-0">Se precisar de ajuda a encontrar o que procura, não hesite em entrar em contacto com a nossa equipa! Temos a certeza que vai encontrar o que procura!</p>
                                                        <a href="/contactos">
                                                            <button
                                                                className="btn btn-outline-primary my-3"
                                                            >
                                                                <i className="fas fa-users me-1"></i>
                                                                Contactos
                                                            </button>
                                                        </a>

                                                    </div>
                                                    // Otherwise, there are no properties to show
                                                    :
                                                    <div>
                                                        <h5 className="font-weight-bold">Sem imóveis disponíveis!</h5>
                                                        <p>
                                                            Lamentamos, mas de momento não dispomos de propriedades na nossa base de dados.
                                                    </p>
                                                        <p>
                                                            Convidamo-lo a visitar-nos em breve, pois certamente teremos novas ofertas para lhe mostrar.
                                                    </p>
                                                        <p>
                                                            Pedimos desculpa por qualquer incómodo causado.
                                                    </p>
                                                    </div>
                                            }
                                        </div>
                        }
                    </div>
                    {
                        filters &&
                        <Filter
                            reloading={reloading}
                            filters={filters}
                            setFilters={setFilters}
                            className={"col-12 col-xl-4 col-xxl-3 order-1 oder-xl-2 mb-5 mb-xxl-0"}
                        />
                    }
                    <Sort
                        open={showSorting}
                        attr={sortAttr}
                        inverse={sortInverse}
                        onClose={(s, i) => {
                            if (s != null && s != null) {
                                setSortAttr(s);
                                setSortInverse(i);
                                setPage(1);
                            }
                            setShowSorting(false);
                        }}
                    />
                </div>
            </Body>
        </div>
    );
}

export default PropertyList;