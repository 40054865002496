import React, { useState, useEffect } from "react";

import {
    TextField,
    Checkbox,
    Typography,
    Slider,
    Grow
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    CheckBoxOutlineBlank,
    CheckBox
} from '@material-ui/icons';

import Loading from "../../../Layouts/Loading";

import "./index.css";

const Filter = ({ filters, setFilters, className, reloading }) => {
    // Get filters from API
    const [locations, setLocations] = useState([]);
    const [features, setFeatures] = useState([]);
    const [types, setTypes] = useState([]);
    const [states, setStates] = useState([]);
    const [prices, setPrices] = useState([0, 0]);
    const [areas, setAreas] = useState([0, 0]);
    const [bedrooms, setBedrooms] = useState([0, 0]);
    const [bathrooms, setBathrooms] = useState([0, 0]);
    useEffect(() => {
        // Make API calls
        fetch(process.env.REACT_APP_API + "/realstate/counties/")
            .then(res => res.json())
            .then(json => {
                setLocations(json);
            });
        fetch(process.env.REACT_APP_API + "/realstate/features/")
            .then(res => res.json())
            .then(json => setFeatures(json));
        fetch(process.env.REACT_APP_API + "/realstate/types/")
            .then(res => res.json())
            .then(json => setTypes(json));
        fetch(process.env.REACT_APP_API + "/realstate/filters/")
            .then(res => res.json())
            .then(json => {
                // Set intervals
                setStates(json['states']);
                setAreas([json['areamin'], json['areamax']]);
                setPrices([json['pricemin'], json['pricemax']]);
                setBedrooms([json['bedroomsmin'], json['bedroomsmax']]);
                setBathrooms([json['bathroomsmin'], json['bathroomsmax']]);
                // Set default filters
                setFilters(prev => ({
                    ...prev,
                    'priceMin': json['pricemin'],
                    'priceMax': json['pricemax'],
                    'areaMin': json['areamin'],
                    'areaMax': json['areamax'],
                    'bedroomsMin': json['bedroomsmin'],
                    'bedroomsMax': json['bedroomsmax'],
                    'bathroomsMin': json['bathroomsmin'],
                    'bathroomsMax': json['bathroomsmax'],
                }));
                // Loading
                setLoading(false);
            });
    }, []);

    // Autocomplete for features
    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    // Loading
    const [loading, setLoading] = useState(true);

    // Handle value change functions
    const sliderChange = (slider, value) => {
        setFilters(prev => ({
            ...prev,
            [slider + 'Min']: value[0],
            [slider + 'Max']: value[1],
        }));
    }

    const selectChange = (select, value) => {
        setFilters(prev => ({
            ...prev,
            [select]: value != null ? value.id : null,
        }));
    }

    const featureChange = (value) => {
        const fids = value.map(f => f.id);
        setFilters(prev => ({
            ...prev,
            features: fids,
        }));
    }

    return (
        <Grow in={true}>

            <div className={"filters " + className} >
                <div className="col-12 bg-white p-4">
                    <div className="d-flex flex-row">
                        {
                            !loading && reloading &&
                            <Loading small={true} className="my-auto me-1" />
                        }
                        <h6 class="font-weight-bold my-auto">Filtros</h6>
                    </div>

                    {
                        loading
                            ?
                            <Loading />
                            :
                            <div>

                                {
                                    prices[1]!=0 && 
                                    <>
                                        <div className="my-3">
                                            <Typography id="range-slider" gutterBottom className="text-secondary">
                                                Preço (milhares)
                                        <span className="small ms-2">
                                                    ({filters['priceMin']/1000}, {filters['priceMax']/1000})
                                        </span>
                                            </Typography>
                                            <Slider
                                                defaultValue={[filters['priceMin']/1000, filters['priceMax']/1000]}
                                                onChangeCommitted={(event, newValue) => sliderChange('price', newValue.map(v => v*1000))}
                                                min={Number(prices[0])/1000}
                                                max={Number(prices[1])/1000}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"
                                                getAriaValueText={(value) => value}
                                                step={1}
                                            />
                                        </div>
                                    </>
                                }
                                
                                {
                                    areas[1]!=0 && 
                                    <>
                                        <div className="my-3">
                                            <Typography id="range-slider" gutterBottom className="text-secondary">
                                                Área
                                        <span className="small ms-2">
                                                    ({filters['areaMin']}, {filters['areaMax']})
                                        </span>
                                            </Typography>
                                            <Slider
                                                defaultValue={[filters['areaMin'], filters['areaMax']]}
                                                onChangeCommitted={(event, newValue) => sliderChange('area', newValue)}
                                                min={Number(areas[0])}
                                                max={Number(areas[1])}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"
                                                getAriaValueText={(value) => value}
                                            />
                                        </div>
                                    </>
                                }

                                {
                                    bedrooms[1]!=0 && 
                                    <>
                                        <div className="my-3">
                                            <Typography id="range-slider" gutterBottom className="text-secondary">
                                                Quartos
                                        <span className="small ms-2">
                                                    ({filters['bedroomsMin']}, {filters['bedroomsMax']})
                                        </span>
                                            </Typography>
                                            <Slider
                                                defaultValue={[filters['bedroomsMin'], filters['bedroomsMax']]}
                                                onChangeCommitted={(event, newValue) => sliderChange('bedrooms', newValue)}
                                                min={Number(bedrooms[0])}
                                                max={Number(bedrooms[1])}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"
                                                getAriaValueText={(value) => value}
                                            />
                                        </div>
                                    </>
                                }

                                {
                                    bathrooms[1]!=0 && 
                                    <>
                                        <div className="my-3">
                                            <Typography id="range-slider" gutterBottom className="text-secondary">
                                                Casas de banho
                                        <span className="small ms-2">
                                                    ({filters['bathroomsMin']}, {filters['bathroomsMax']})
                                        </span>
                                            </Typography>
                                            <Slider
                                                defaultValue={[filters['bathroomsMin'], filters['bathroomsMax']]}
                                                onChangeCommitted={(event, newValue) => sliderChange('bathrooms', newValue)}
                                                min={0}
                                                max={Number(bathrooms[1])}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"
                                                getAriaValueText={(value) => value}
                                            />
                                        </div>
                                    </>
                                }

                                {
                                    locations.length &&
                                    <Autocomplete
                                        id="location"
                                        options={locations}
                                        getOptionLabel={(option) => option.name}
                                        style={{ width: "100%" }}
                                        renderInput={(params) => <TextField {...params} label="Localização" />}
                                        className="my-3"
                                        onChange={(event, value) => selectChange('location', value)}
                                        value={locations.filter(l => l.id === filters['location'])[0]}
                                    />
                                }

                                {
                                    states.length && 
                                    <Autocomplete
                                        id="state"
                                        options={states}
                                        getOptionLabel={(option) => option.name}
                                        style={{ width: "100%" }}
                                        renderInput={(params) => <TextField {...params} label="Finalidade" />}
                                        className="my-3"
                                        onChange={(event, value) => selectChange('state', value)}
                                        value={states.filter(l => l.id === filters['state'])[0]}
                                    />
                                }

                                {
                                    types.length &&
                                    <Autocomplete
                                        id="type"
                                        options={types}
                                        getOptionLabel={(option) => option.name}
                                        style={{ width: "100%" }}
                                        renderInput={(params) => <TextField {...params} label="Tipo de propriedade" />}
                                        className="my-3"
                                        onChange={(event, value) => selectChange('type', value)}
                                    />
                                }

                                {
                                    features.length &&
                                    <Autocomplete
                                        multiple
                                        id="features"
                                        options={features}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                    color="primary"
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        style={{ width: "100%" }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Características" placeholder="" />
                                        )}
                                        onChange={(event, value) => featureChange(value)}
                                    />
                                }

                            </div>
                    }

                </div>
            </div>
        </Grow>
    );
}

export default Filter;