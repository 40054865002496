/*
This script defines functions for forms validation

The available classes are
    str         String
    email       Email
    phone       Phone number
    bool        Boolean value

--- Parameters
form            The form object { attributeName: value, * }
attrsClass      The attributes classification { attributeName: className, * }
attrsName       The attributes names to display on interface { attributeName: displayName, * }

--- Returns
errors          A list with the display names of the invalid fields. Empty list if form is valid.
*/

const validateForm = (form, attrsClass, attrsName) => {
    let errors = [];

    console.log(form);

    Object.keys(form).forEach(attr => {
        let expr = null;

        // Find regex for attribute class
        switch(attrsClass[attr]) {
            case 'str':
                expr = /^.*\w+.*$/
                break;
            case 'email':
                expr = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                break;
            case 'phone':
                expr = /^\+?[0-9]{9,12}$/;
                break;
            case 'bool':
                expr = /^true$/;
                break;
            default:
                // If attribute not found, add to errors list
                errors.push(attrsName[attr]);
        }

        /*
        console.log("------- " + attr);
        console.log(form[attr]);
        console.log(attrsClass[attr]);
        console.log(expr);
        console.log(String(form[attr]).trim());
        console.log(expr.test(String(form[attr]).trim()));
        */

        // Validate value with regex expression
        if (expr!=null && (form[attr]==null || !expr.test(String(form[attr]).trim()))) {
            errors.push(attrsName[attr]);
        }
    });

    return errors;
}

export default validateForm;