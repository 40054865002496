import React, { useState, useEffect } from "react";

import { Grow } from '@material-ui/core';

import NavItem from "./NavItem";

import "./index.css";

const Navbar = ({ transparent }) => {
    const [menu, setMenu] = useState([]);
    const [expanded, setExpanded] = useState(window.innerWidth >= 992 ? true : false);

    useEffect(() => {
        fetch(process.env.REACT_APP_API + "/pages/menus/")
            .then(res => {
                if (!res.ok) { throw res }
                return res.json();
            })
            .then(json => setMenu(json))
            .catch(err => {
                if (err.status===404 || err.name==='TypeError') {
                    window.location.href = "/manutencao?goBackTo=" + window.location.pathname;
                }
            })
    }, []);

    const colorClass = transparent ? "bg-transparent" : "bg-white";

    return (
        <nav className={
            window.location.pathname=='/'
                ? "col-12 col-lg-9 px-3 px-lg-0 mx-auto navbar navbar-expand-lg navbar-light navbar-dark " + colorClass
                : "col-12 col-lg-9 px-3 px-lg-0 mx-auto navbar navbar-expand-lg navbar-light navbar-light " + colorClass
            }>
            <a className="navbar-brand" href="/"><img width="100" src={process.env.PUBLIC_URL + "/static/react/logo.png"} alt="Logótipo Chave Lusa"/></a>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setExpanded(prev => !prev)}
            >
                <span className="navbar-toggler-icon"></span>
            </button>

            <Grow
                in={expanded}
                style={{
                    display: expanded ? "block" : "none"
                }}
            >
                <div
                    className="collapse navbar-collapse collapse show"
                    id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        {menu.map((m, index) => <NavItem m={m} index={index} />)}
                        <NavItem m={{
                            label: 'Favoritos',
                            link: '/favoritos',
                            order: 99,
                            blank: false,
                            children: [],
                            icon: 'far fa-heart'
                        }} index={99} />
                    </ul>
                </div >
            </Grow>

        </nav >
    )
}

export default Navbar;