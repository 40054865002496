import React from "react";

const TopBar = ({children}) => {
    return (
        <section className="bg-primary">
            <div className="col-12 col-lg-9 px-3 px-lg-0 py-3 mx-auto d-flex flex-row">
                {children}
            </div>
        </section>
    );
}

export default TopBar;