import React, { useState, useEffect } from "react";

import "./index.css";

const Cookies = () => {

    // Auxiliar functions
    const setCookie = () => {
        document.cookie = "visitedthissession=yes;path=/";
        setCookies(false);
    }

    const getCookie = () => {
        var name = "visitedthissession=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // Cookies control
    const [cookies, setCookies] = useState(false);

    // On load, check if user has already given consent 
    useEffect(() => {
        const user = getCookie("visitedthissession");
        if (user === "" && window.location.href.indexOf("privacidade") < 0 && window.location.href.indexOf("cookie") < 0) {
            setCookies(true);
        }
    }, []);

    return (
        <div 
            className={ cookies ? "position-fixed b-0 h-100 w-100 bg-dark-transparent" : "d-none"} 
        >
            <div class="bg-white w-100 position-fixed b-0 m-0 br-0 py-5 px-0" role="alert">
                <div class="col-12 col-lg-9 px-3 px-lg-0 mx-auto">
                    <h5 class="font-weight-bold">Este site utiliza cookies</h5>
                    Ao navegar no nosso <span class="text-italic">site</span> está a concordar com a utilização de <span class="text-italic">cookies</span>. Para mais informações pode consultar a nossa <a href="/cookies" class="text-primary font-weight-bold" target="_blank">política de <span
                        class="text-italic">Cookies</span>!</a>
                    <button 
                        type="button" 
                        class="btn btn-primary btn-sm btn-alert ms-3"
                        onClick={() => setCookie()}
                    >Aceitar</button>
                </div>
            </div>
        </div>
    );
}

export default Cookies;