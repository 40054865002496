import React from "react";

import LazyImage from "../../Components/Images/LazyImage";

const About = () => {
    return (
        <div
            className="my-5 mx-auto col-12 col-lg-9 px-3 px-lg-0"
        >
            <h2
                className="text-center"
            >
                Quinze anos de experiência no ramo imobiliário!
            </h2>

            <div className="d-flex flex-row flex-wrap my-5">
                <div className="col-12 col-xxl-6 pe-xxl-4 text-center text-xxl-start order-2 order-xxl-1">
                    <p><strong>CHAVE LUSA</strong> é uma empresa dinâmica, pronta a ajudar tanto o Vendedor, como o Comprador e o Arrendatário a concretizarem os seus objetivos.</p>
                    <p>O nosso objetivo é conseguir encontrar compradores para os nossos proprietários que nos confiaram os seus imóveis em exclusivo e acompanhá-los antes, durante e após a escritura.</p>
                    <p>Para os nossos compradores será encontrar o melhor investimento possível dentro das características do imóvel pretendido com um padrão de honestidade na prestação do serviço imobiliário e garantindo-lhe a partilha com todas as imobiliárias locais e Nacionais.</p>
                    <a
                        href="/sobrenos"
                    >
                        <button
                            className="btn btn-primary"
                        >
                            Saiba mais
                        </button>
                    </a>
                </div>
                <div className="col-12 col-xxl-6 ps-xxl-4 order-1 order-xxl-2 mb-5">
                    <LazyImage 
                        src={process.env.PUBLIC_URL + "/static/react/agency/agency.jpg"} 
                        className="col-12"
                    />
                </div>
            </div>
        </div>
    );
}

export default About;