import { Tooltip } from "@material-ui/core";
import React from "react";

const Arrow = ({ className, style, onClick, side }) => {

    let src = process.env.PUBLIC_URL + "/static/react/gallery/chevronright.png";

    if (side === "left") {
        src = "/static/react/gallery/chevronleft.png";
    }

    return (
        <Tooltip title={side==="left" ? "Anterior" : "Seguinte"}>
            <img
                className={className}
                style={{
                    ...style,
                    zIndex: 2,
                    height: "auto"
                }}
                src={src}
                onClick={onClick}
                alt={side==="left" ? "Anterior" : "Seguinte"}
            />
        </Tooltip>
    )
}

export default Arrow;