import React, { useState, useEffect } from 'react';

import TopBar from "../../Layouts/Topbar";
import Body from "../../Layouts/Body";

import Loading from "../../Layouts/Loading";
import PropertySquare from "../../Components/Property/PropertySquare";
import Pagination from "../../Components/Pagination";
import Sort from "../../Components/Property/Sort";
import AgentCard from "../../Components/Agents/AgentCard";

import {
    useParams
} from "react-router-dom";


const Agent = () => {

    let { id } = useParams();

    // Pagination
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [items, setItems] = useState(0);

    // Loading
    const [loading, setLoading] = useState(true);

    // Sorting
    const [sortAttr, setSortAttr] = useState("price");
    const [sortInverse, setSortInverse] = useState("-");
    const [showSorting, setShowSorting] = useState(false);

    // Data from API
    const [properties, setProperties] = useState([]);
    const [agent, setAgent] = useState(null);
    useEffect(() => {
        let url = process.env.REACT_APP_API + "/realstate/properties/";
        // Pagination
        url += "?page=" + page;
        // Sort
        url += "&ordering=" + sortInverse + sortAttr;
        // Agent
        url += "&agent=" + id;
        fetch(url)
            .then(res => res.json())
            .then(json => {
                setProperties(json['results']);
                setPage(json['currentPage']);
                setItemsPerPage(json['itemsPerPage']);
                setItems(json['count']);
            })
            .catch((error) => {
                window.location.href = "/404";
            });
        fetch(process.env.REACT_APP_API + "/realstate/agents/" + id)
            .then(res => res.json())
            .then(json => {
                // 404 Error
                if ('detail' in json && json['detail'] === 'Not found.') {
                    window.location.href = "/404";
                }
                setAgent(json);
                setLoading(false);
            })
            .catch((error) => {
                window.location.href = "/404";
            });
    }, [page, sortAttr, sortInverse, id]);

    // Scroll top on page change
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [page]);

    return (
        <div>
            <TopBar>
                {
                    !loading &&
                    <div className="col-12 d-flex flex-row p-0 m-0">
                        <h4 class="font-weight-bold text-light mb-0">
                            Página do agente <small>{agent && agent.name}</small>
                        </h4>
                        <p
                            className="ms-auto my-auto text-white link nowrap"
                            onClick={() => setShowSorting(true)}
                        >
                            <i className="fas fa-sort me-1"></i>
                            Ordenação
                        </p>
                    </div>
                }
            </TopBar>
            <Body>
                {
                    loading
                        // On loading show Loading gif
                        ?
                        <Loading />
                        // Otherwise, show page content
                        :
                        <div className="col-12 d-flex flex-row flex-wrap">

                            <AgentCard
                                agent={agent}
                                agents={3}
                            />

                            <div className={"col-12 col-xl-8 pl-xl-8"}>
                                {
                                    properties.length > 0
                                        // If there are properties to show, show them
                                        ?
                                        <div className="col-12 d-flex flex-row flex-wrap">
                                            {
                                                properties.map(
                                                    property =>
                                                        <PropertySquare
                                                            property={property}
                                                            colsNumber={2}
                                                            showAgent={false}
                                                        />
                                                )
                                            }
                                            <Pagination
                                                current={page}
                                                itemsPerPage={itemsPerPage}
                                                items={items}
                                                handleChange={setPage}
                                                className="mb-5"
                                            />
                                        </div>
                                        // If not, show erros message
                                        :
                                        <div className="col-12">
                                            {
                                                <div>
                                                    <h5 className="font-weight-bold">
                                                        Não existem imóveis associados a este agente!
                                                    </h5>
                                                    <p>Lamentamos, mas esta agente ainda não publicou nenhuma propriedade. No entanto, dispomos de outros colaboradores que com certeza o poderão ajudar. Consulte <a href="/propriedades">aqui</a> a lista das nossas propriedades.</p>
                                                </div>
                                            }
                                        </div>
                                }
                            </div>
                            <Sort
                                open={showSorting}
                                attr={sortAttr}
                                inverse={sortInverse}
                                onClose={(s, i) => {
                                    if (s != null && s != null) {
                                        setSortAttr(s);
                                        setSortInverse(i);
                                        setPage(1);
                                    }
                                    setShowSorting(false);
                                }}
                            />
                        </div>
                }
            </Body>
        </div>
    );
}

export default Agent;