import React from "react";

const Loading = ({small, className}) => {
    return (
        <div className={small ? className : "col-12 d-flex my-auto " + className}>
            <div className={small ? "spinner-grow spinner-grow-sm text-primary mx-auto" : "spinner-grow text-primary mx-auto"} role="status">
                <span class="visually-hidden">A carregar...</span>
            </div>
        </div>
    );
}

export default Loading;

/* Usage
// Loading
const [loading, setLoading] = useState(true);

// useEffect (inside fetch)
setLoading(false);
*/