const PropertyService = {
    // Favorites management
    getFavorites: () => {
        if (!window.localStorage.getItem("favorites"))
            return []; 
        return JSON.parse(window.localStorage.getItem("favorites"));
    },
    addToFavorites: (id) => {
        let favorites = PropertyService.getFavorites();
        if (favorites.indexOf(id)<0)
            favorites.push(id);
        window.localStorage.setItem("favorites", JSON.stringify(favorites));
    },
    removeFromFavorites: (id) => {
        let favorites = PropertyService.getFavorites();
        if (favorites.indexOf(id)>=0)
            favorites.splice(favorites.indexOf(id), 1);
        window.localStorage.setItem("favorites", JSON.stringify(favorites));
    },

    getArea: (n) => {
        // Returns [Number(Double), Unit(String)]
        if (n>10000) {
            return [(n/10000).toFixed(1), 'ha'];
        }
        return [n, 'm²']
    },
};

export default PropertyService;