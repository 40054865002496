import React, { useState, useEffect } from "react";

import "./index.css";

const LazyImage = ({src, className, title, onClick}) => {

    const [loading, setLoading] = useState(1);

    useEffect(() => {
        setLoading(1);
        const img = new Image();
        img.src = src;
        img.onload = () => setLoading(0);
    }, [src]);

    return (
        <div 
            className={"imageload " + className}
            title={title}
            onClick={onClick}
        >
            <img 
                src={src}
                className="col-12 h-100"
                alt="Imagem Chave Lusa"
            />
            <div 
                className="overlay"
                style={{
                    opacity: loading
                }}    
            ></div>
        </div>
    );
}

export default LazyImage;