import React, { useState, useEffect } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    TextField,
    Grow,
    Grid
} from '@material-ui/core';

import Navbar from "../../Layouts/Navbar";
import Loading from "../../Layouts/Loading";

import About from "./About";
import { CodeSharp } from '@material-ui/icons';

const Homepage = () => {

    // Loading
    const [loading, setLoading] = useState(true);

    // Get data from API
    const [banner, setBanner] = useState(null);
    const [locations, setLocations] = useState([]);
    const [states, setStates] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_API + "/realstate/randombanner/")
            .then(res => res.json())
            .then(json => {
                // If banner
                if(json['property']) {
                    setBanner(json);
                    // Wait for it to load 
                    const img = new Image();
                    img.src = process.env.REACT_APP_STATIC + json['image']
                    img.onload = () => setLoading(false);
                    img.onerror = () => {
                        setBanner(null);
                        setLoading(false);
                    };
                } else {
                    setLoading(false);
                }
            }).catch((error) => {
                setLoading(false);
            });
        fetch(process.env.REACT_APP_API + "/realstate/counties/")
            .then(res => res.json())
            .then(json => setLocations(json))
            .catch((error) => {
                setLoading(false);
            });;
        fetch(process.env.REACT_APP_API + "/realstate/filters/")
            .then(res => res.json())
            .then(json => {
                // Set intervals
                setStates(json['states'].filter(s => s.id!=='O' && s.id!=='E').map(s => {
                    if (s.id==='S') {
                        s.name = 'Comprar'
                    }
                    return s;
                }));
            })
            .catch((error) => {
                setLoading(false);
            });;
    }, []);

    // Filtering
    const [filters, setFilters] = useState({});
    const [reference, setReference] = useState(undefined);
    const [invalidReference, setInvalidReference] = useState(false);

    const selectChange = (select, value) => {
        setFilters(prev => ({
            ...prev,
            [select]: value != null ? value.id : null,
        }));
    }

    // Search
    const search = () => {
        if (reference) {
            fetch(process.env.REACT_APP_API + "/realstate/properties/" + reference.trim())
            .then(res => {
                if (res.ok) {
                    window.location.replace('/propriedades/' + reference.trim());
                } else {
                    setInvalidReference(true);
                }
            })
        } else {
            let url = "/propriedades?";
            console.log("FILTERS", filters)
            // Apply filters
            Object.entries(filters).forEach(([key, value]) => {
                if (value != null) {
                    url += key + "=" + value + "&";
                }
            });
            window.location.replace(url);
        }
    }

    return (
        loading
            ?
            <Loading />
            :
            <div>
                <div
                    style={{
                        backgroundImage: 
                            banner 
                            ? 
                            "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + process.env.REACT_APP_STATIC + banner.image + ")" 
                            :
                            "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + process.env.PUBLIC_URL + "/static/react/home/background.jpg)" ,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        minHeight: "85vh"
                    }}
                    className="d-flex flex-column"
                >
                    <Grow
                        in={!loading}
                    >
                        <Navbar
                            transparent={true}
                        />
                    </Grow>
                    <Grow
                        in={!loading}
                    >
                        <div
                            className="mx-auto col-12 col-lg-9 px-3 px-lg-0 z-100 d-flex flex-column flex-grow-1"
                        >
                            <h1
                                className="text-center font-weight-bold text-white display-4 py-4 my-4 my-sm-5 py-5"
                            >
                                Como é a casa dos seus sonhos?
                            </h1>
                            <div
                                className="col-12 bg-white p-4 mx-auto d-flex flex-row flex-wrap"
                            >

                                <Autocomplete
                                    id="state"
                                    options={states}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Finalidade" />}
                                    className="my-3 col-12 col-md-6 col-xxl-3 pl-md-3 me-auto"
                                    onChange={(event, value) => selectChange('state', value)}
                                />

                                <Autocomplete
                                    id="location"
                                    options={locations}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Localização" />}
                                    className="my-3 col-12 col-md-6 col-xxl-3 ps-md-3 pe-xxl-3 mx-auto"
                                    onChange={(event, value) => selectChange('location', value)}
                                />

                                <Grid className="my-3 col-12 col-md-6 col-xxl-3 pe-md-3 pe-xxl-3 mx-auto">
                                    <TextField 
                                        id="reference" 
                                        label="Referência" 
                                        variant="standard"
                                        className="w-100"
                                        onChange={(e) => {setInvalidReference(false); setReference(e.target.value)}}

                                        error={invalidReference}
                                        helperText={invalidReference ? 'Referência inválida' : undefined}
                                    />
                                </Grid>

                                <button
                                    type="button"
                                    className="btn btn-outline-primary my-3 col-12 col-xxl col-md ps-xxl-3 ms-md-auto"
                                    onClick={search}
                                >
                                    <i className="fas fa-search me-1"></i>
                                    Pesquisar
                                </button>
                            </div>
                            {
                                banner &&
                                <p
                                    className="small text-white text-end mt-auto"
                                >
                                    <i class="fas fa-info-circle me-2"></i>
                                    <a 
                                        href={"/propriedades/" + banner.property.id}
                                        className="text-white"
                                    >
                                        {banner.property.title}
                                    </a>
                                    <span
                                        className="me-2"
                                    >,</span>
                                    <a 
                                        href={"/propriedades/?location=" + banner.property.county.id}
                                        className="text-white"    
                                    >
                                        {banner.property.county.name}
                                    </a>
                                </p>
                            }
                        </div>
                    </Grow>
                </div>
                <About />
            </div>
    );
}

export default Homepage;