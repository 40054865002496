import React from "react";

const Footer = () => {
    return (
        <footer className="bg-tertiary">
            <div className="col-12 col-lg-9 px-3 py-3 px-lg-0 row mx-auto small">
                <div className="col-12 col-md-6 col-lg-4 px-0 py-2">
                    <p className="mb-0 text-center text-md-left">&copy; Homesingular, LDA 2020-2021</p>
                    <p className="mb-0 text-center text-md-left">Licença 17523-AMI</p>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-0 py-2 nothome">
                    <a href="/privacidade" target="_blank" rel="noreferrer"><p className="m-0 text-dark text-center text-md-left text-lg-center">Política de Privacidade</p></a>
                    <a href="/litigios" target="_blank" rel="noreferrer"><p className="m-0 text-dark text-center text-md-left text-lg-center">Centros de Resolução de Litígios</p></a>
                    <a href="https://www.livroreclamacoes.pt/inicio" target="_blank" rel="noreferrer"><p className="m-0 text-dark text-center text-md-left text-lg-center">Livro de Reclamações</p></a>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-0 py-2 nothome">
                    <p className="mb-0 text-center text-md-left text-lg-right">Desenvolvido por <a className="text-secondary" href="https://gmatos.pt" target="_blank" rel="noreferrer">Gonçalo Matos</a></p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;