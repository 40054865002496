import React, { useState } from "react";

import EmailShareDialog from "./EmailShareDialog";

import './index.css';

const EmailShare = ({className, property}) => {
    const [open, setOpen] = useState(false);

    return (
        <div
            className="h-100"
        >
            <btn
                className={"btn btn-email-share " + className}
                style={{
                    padding: '12.5px',
                    display: 'flex'
                }}
                onClick={() => setOpen(true)}
            >
                <i 
                    class="fas fa-envelope"
                    style={{
                        marginTop: 'auto',
                        marginBottom: 'auto'
                    }}
                ></i>
            </btn>
            <EmailShareDialog 
                open={open}
                setOpen={setOpen}
                property={property}
            />
        </div>
    );
}

export default EmailShare;