import React from "react";

import LazyImage from "../../Images/LazyImage";

import phoneBeautify from "../functions";

const AgentCard = ({ agent, agents }) => {

    let col = 4;
    if (agents === 0) {
        col=12;
    } else if (agents%3 === 0) {
        col = 4; 
    } else if (agents%4 === 0) {
        col = 3;
    } else if (agents%2 === 0) {
        col = 6;
    }

    return (
        <div className={"col-12 p-0 mb-5 mx-auto col-xl-" + col}>
            <div className="bg-white py-5">
                <a href={"/agente/" +  agent.id}>
                    <LazyImage 
                        className="col-6 offset-3 round" 
                        src={
                            agent.image 
                            ?
                            process.env.REACT_APP_STATIC + agent.image 
                            : 
                            process.env.PUBLIC_URL + "/static/react/agent/agent.jpg"
                        }
                    />
                </a>
                <h5 className="mt-3 text-center font-weight-bold">{agent.name}</h5>
                <p className="text-center"><a href={"tel:00351"+agent.contact}>{phoneBeautify(agent.contact)}</a></p>
            </div>
        </div>
    );
}

export default AgentCard;