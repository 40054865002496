import React from "react";

import priceNotation from "../../Components/Property/functions";

const Header = ({ property }) => {
    return (
        <div className="bg-primary">
            <div className="col-12 col-lg-9 px-3 px-lg-0 py-3 mx-auto d-md-flex flex-md-row">
                {
                    property &&
                    <>
                        <div className="col-12 col-md-9 col-xl-10 px-0 pt-3 pb-2 pb-md-3 mr-auto">
                            <h4 className="font-weight-bold text-light mb-0">{ property.title }</h4>
                            <p className="text-light mb-0">{ property.parish.county.name }</p>
                        </div>
                        <div className="col-12 col-md-3 col-xl-2 px-0 pb-3 pt-2 pt-md-3 text-end d-flex flex-column">
                            <h4 className="font-weight-bold text-light mb-0 oder-1 order-md-2">
                                {property.price ? priceNotation(property.price) + "€" : "Preço sob consulta"}
                            </h4>
                            <p className="text-light mb-0 oder-2 order-md-1">
                                {
                                    // Words finished in "ado" or "ido" (Eg. Arrendado, Vendido) should not be preceeded by "Para"
                                    property.state.indexOf("ido")<0 && property.state.indexOf("ado")<0 && "Para "
                                }
                                { property.state }
                            </p>
                            <p className="small m-0 p-0">
                                { 
                                    property.negociable &&
                                    <span className="ml-1">Negociável</span>
                                }
                                {
                                    property.exchange &&
                                    <span className="ml-1">Aceita Permuta</span>
                                }
                                {
                                    property.bankproperty &&
                                    <span className="ml-1">Propriedade do Banco</span>
                                }
                            </p>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default Header;