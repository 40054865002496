import React from "react";
import { Outlet } from 'react-router-dom';

import Navbar from "./Navbar";
import Footer from "./Footer";

const MainLayout = () => {
    return (
        <div
            className="col-12 d-flex flex-column"
        >
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    );
}

export default MainLayout;